// small
$icon-w-sm: 18px;
$icon-h-sm: 14px;

// medium

// large
$icon-w-lg: 56px;
$icon-h-lg: 56px;

// 20px
$icon-w-20: 20px;
$icon-h-20: 20px;

// closeIcon
$icon-w-close: 14px;
$icon-h-close: 14px;

// 16px
$icon-w-16: 16px;
$icon-h-16: 16px;

// twitter
$icon-w-twitter: 20px;
$icon-h-twitter: 16px;

// facebook
$icon-w-facebook: 10px;
$icon-h-facebook: 18px;
