@import "src/styles/index";

.location {
  &.mb-6 {
    margin-bottom: spacing(6);
  }

  &.mb-0 {
    margin-bottom: 0;
  }

  .text {
    color: $main-text-color;
    font-weight: 400;
    margin-left: spacing(2);
  }
}
