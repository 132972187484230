@import "src/styles/index";

.title {
  font-weight: 600;

  &.main {
    color: $main-text-color;
  }

  &.white {
    color: $white-text-color;
  }

  &.mb-12 {
    margin-bottom: spacing(12);

    @media (max-width: 768px) {
      margin-bottom: spacing(6);
    }
  }

  &.mb-4 {
    margin-bottom: spacing(4);
  }

  &.my-12 {
    margin: spacing(12) 0;
  }

  &.text-start {
    text-align: start;
  }

  &.text-center {
    text-align: center;
  }
}
