@import "src/styles/index";

.wrapperLink {
  font-weight: 600;
  text-align: center;

  &.medium {
    max-width: 170px;
    width: 100%;
  }

  &.large {
    width: 200px;
  }

  &.maxWidth {
    width: 100%;
  }

  &.primaryColor {
    background-color: $bg-primary;
    padding: spacing(3) spacing(3);
    border-radius: 5px;
    color: $white-text-color;

    &:hover {
      color: $white-text-color;
    }
  }

  &.secondaryColor {
    background-color: transparent;
    border: 2px solid $primary-color;
    color: $primary-text-color;
    border-radius: 5px;
    padding: spacing(3) spacing(3);
  }
}
