@import "src/styles/index";

.customBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 5px;
  font-family: 'Raleway', sans-serif;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  padding: spacing(4) 0;

  &.small {
    width: 100px;
    padding: spacing(3) 0;
  }

  &.medium {
    width: 176px;
  }

  &.large {
    width: 100%;
  }

  &.customSize {
    width: 230px;
    padding: 15px 0;
  }

  &.MissionSearchBtn {
    max-width: 320px;
    width: 100%;
  }

  &.circleSize {
    width: 20px;
    height: 20px;
  }
}

.text {
  line-height: 19px;
}

.facebook {
  background-color: #4267b2;
  border-color: #4267b2;
  color: $white-text-color;
}

.primary {
  color: $white-text-color;
  background-color: $bg-primary;
  border-color: $bg-primary;
  &[disabled] {
    color: $dark-gray-color;
    background: $middle-gray-color;
  }
}

.outlinePrimary {
  color: $primary-text-color;
  background-color: transparent;
  border: 2px solid $primary-color;
}

.icon {
  margin-right: spacing(2);
}

.danger {
  color: $white-text-color;
  background-color: $bg-danger;
  border-color: $danger-color;
}

.secondary {
  color: $dark-gray-text-color;
  background-color: $bg-disabled;
  border-color: $bg-disabled;
}

.success {
  color: $white-text-color;
  background-color: $bg-success;
  border-color: $success-color;
}

.primaryNotBg {
  border: none;
  background-color: transparent;
  color: $primary-text-color;
}

.circle {
  border-radius: 50%;
  background-color: $primary-color;
  color: $white-text-color;
  padding: 0;
  margin: 0;
}

.disableCircle {
  border-radius: 50%;
  background-color: $middle-gray-color !important;
  color: $dark-gray-text-color;
  padding: 0;
  margin: 0;
}
