@import "src/styles/index";

.blockWithForm {
  max-width: 370px;
  max-height: 380px;
  width: 100%;
  box-shadow: 0 10px 20px rgba(38, 38, 38, .03);
  border-radius: 10px;
  padding: spacing(10) spacing(8);
  background-color: $bg-tertiary;
  margin-left: spacing(18);

  .wrapperInputs {
    display: flex;
    justify-content: space-between;
    margin-bottom: spacing(2);

    .blockWithInput {
      max-width: 138px;
    }
  }

  .blockWithDropdown {
    margin-bottom: spacing(6);
  }

  .footer {
    margin-top: spacing(6);
    font-size: $font-size-14;
    font-weight: 500;
    color: $dark-gray-text-color;
  }
}
