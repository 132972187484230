@import "~slick-carousel/slick/slick";
@import "~slick-carousel/slick/slick-theme";
@import "fonts/export";
@import "variables/export";
@import "globals";
@import "mixins";
@import "functions";
@import "spacing";
@import "inputs/export";
@import "classes/export";

.loading-overlay:before {
  content: '';
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, .1);
}
