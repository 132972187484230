.sectionBanner {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: transparent;

  @media (max-width: 992px) {
    height: unset;
  }

  &.medium {
    padding: 100px 0;
  }

  &.large {
    padding: 144px 0;
  }

  &.primaryRadius {
    border-radius: 0 0 20px 20px;
  }

  &.defaultRadius {
    border-radius: 0;
  }
}
