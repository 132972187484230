@import "src/styles/variables/icons";

.sm {
  min-width: $icon-w-sm;
  min-height: $icon-h-sm;
  width: $icon-w-sm;
  height: $icon-h-sm;
}

.lg {
  max-width: $icon-w-lg;
  max-height: $icon-h-lg;
  width: $icon-w-lg;
  height: $icon-h-lg;
}

.w-20px {
  max-width: $icon-w-20;
  max-height: $icon-h-20;
  width: $icon-w-20;
  height: $icon-h-20;
}

.close {
  cursor: pointer;
  max-width: $icon-w-close;
  max-height: $icon-h-close;
  width: $icon-w-close;
  height: $icon-h-close;
}

.show {
  max-width: $icon-w-close;
  max-height: $icon-h-close;
  width: $icon-w-close;
  height: $icon-h-close;
}

.linkedin {
  max-width: $icon-w-16;
  max-height: $icon-h-16;
}

.twitter {
  max-width: $icon-w-twitter;
  max-height: $icon-h-twitter;
}

.facebook {
  max-width: $icon-w-facebook;
  max-height: $icon-h-facebook;
}

.instagram {
  max-width: $icon-w-20;
  max-height: $icon-h-20;
}
