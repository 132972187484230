@import "src/styles/index";

.social {
  width: 32px;
  height: 32px;
  border-radius: 5px;

  &.linkedin {
    background-color: #2867b2;
  }

  &.twitter {
    background-color: #00acee;
  }

  &.facebook {
    background-color: #3b5998;
  }

  &.instagram {
    background: radial-gradient(144.38% 122.74% at 1.82% 90.84%, #fed576 0%, #f47133 26.34%, #bc3081 60.91%, #4c63d2 100%);
  }

  &.spacing {
    margin-right: spacing(4);
  }
}
