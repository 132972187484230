// Main Colors
$color-main: #262626;
$primary-color: #0086cd;

$light-gray-color: #f9f9f9;
$middle-gray-color: #ebebeb;
$dark-gray-color: #8d8d8d;

$success-color: #66bb6a;
$danger-color: #ff5d29;
$color-white: #fff;

// Text Colors
$main-text-color: $color-main;
$primary-text-color: $primary-color;
$secondary-text-color: $dark-gray-color;
$white-text-color: $color-white;
$middle-gray-text-color: $middle-gray-color;
$dark-gray-text-color: $dark-gray-color;

$color-link: $primary-color;

// Background Colors
$bg-main: #f8f8f8;
$bg-primary: $primary-color;
$bg-secondary: $color-main;
$bg-tertiary: $color-white;
$bg-disabled: $middle-gray-color;
$bg-success: $success-color;
$bg-danger: $danger-color;
