@import "src/styles/index";

.link {
  &:not(:last-child) {
    padding-right: spacing(7);
    position: relative;

    &:before {
      content: " ";
      position: absolute;
      border: 4px solid transparent;
      border-left-color: $dark-gray-color;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
    }
  }

  .title {
    font-weight: 600;
    color: $primary-text-color;
  }
}
