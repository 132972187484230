.form-group {
  margin-bottom: spacing(6);

  .form-control {
    margin-top: spacing(2);
    background-color: $bg-tertiary;
    border-radius: 5px;
    border: 1px solid $middle-gray-color;
    padding: 16px;
    height: 48px;
  }
}
