@import "src/styles/index";

.blockWithVideo {
  &.mb-8 {
    margin-bottom: spacing(8);
  }

  .video {
    object-fit: cover;

    @media (min-width: 768px) {
      width: 470px;
      height: 280px;
    }

    @media (max-width: 767px) {
      width: 270px;
      height: 170px;
    }
  }
}
