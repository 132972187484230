// Fonts Size
$font-size-base: 16px;
$font-size-20: 20px;
$font-size-14: 14px;

$h1-font-size: 32px;
$h2-font-size: 26px;
$h3-font-size: 20px;
$h4-font-size: 16px;
$h5-font-size: 14px;
