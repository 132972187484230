@import "src/styles/index";

.labelStyles {
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 8px;

  &.white {
    color: $white-text-color;
  }

  &.black {
    color: $main-text-color;
  }
}

.baseDropdown {
  box-shadow: 0 10px 20px rgba(38, 38, 38, .03);

  .dropdownMenu {
    padding: spacing(4) 0 spacing(6) 0;
    width: 100%;
    margin: 0;
    border-radius: 0 0 5px 5px;
    border: 1px solid $light-gray-color;
    background-color: $light-gray-color;
  }

  .formControl {
    width: 100%;
    padding: spacing(3);
    background-color: $bg-tertiary;
    border-radius: 5px;
    color: $main-text-color;
    border: 1px solid #ebebeb;
    height: 50px;

    &.Show {
      border-radius: 5px 5px 0 0;
    }

    &:hover,
    &:focus,
    &:active {
      outline: none;
      background-color: $bg-tertiary;
      color: $main-text-color;
      box-shadow: none;
      border: 1px solid $light-gray-color;
    }
  }
}
