@import "src/styles/index";

.blockWithStructure {
  .topBlock {
    margin-bottom: spacing(12);

    .image {
      max-width: 112px;
      width: 100%;
      height: 48px;
      object-fit: cover;
      margin-right: spacing(6);
    }

    .link {
      color: $primary-text-color;
      font-weight: 600;
    }
  }

  .blockWithDescription {
    margin-bottom: spacing(10);

    .title {
      font-weight: 600;
      margin-bottom: spacing(2);
    }
  }
}
