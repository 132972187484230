@import "src/styles/index";

.tabs {
  margin-bottom: spacing(10);

  .tab {
    display: flex;
    justify-content: center;
    width: 20%;
    position: relative;
    border: none;
    background-color: transparent;
    color: $dark-gray-text-color;

    &:after {
      position: absolute;
      content: '';
      width: 100%;
      height: 4px;
      left: 0;
      bottom: 0;
      background-color: $middle-gray-color;
    }

    &.active {
      position: relative;
      background-color: transparent;
      color: $main-text-color;
      font-weight: 600;

      &:after {
        position: absolute;
        content: '';
        width: 100%;
        height: 4px;
        left: 0;
        bottom: 0;
        background-color: $primary-color;
      }
    }
  }
}
