@import "src/styles/index";

.wrapperMissionSearch {
  max-width: 670px;
  margin: 0 auto;

  .title {
    color: $white-text-color;
    margin-bottom: spacing(8);
    font-weight: 600;
  }

  .blockWithSelect {
    max-width: 320px;
    width: 100%;
  }

  .blockWithInput {
    max-width: 320px;
    width: 100%;

    &.small {
      max-width: 148px;

      &:last-child {
        margin-left: spacing(6);
      }
    }
  }

  .blockWithBtn {
    max-width: 320px;
    width: 100%;
    align-items: flex-end;
  }
}
