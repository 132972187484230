@import "src/styles/index";

.advantagesCarouselSection {
  background-color: $bg-tertiary;
  padding: 104px 0;

  .slick-dots {
    width: auto;
    bottom: 90px;
    left: 700px;

    @media (max-width: 1199px) {
      left: 530px;
    }

    @media (max-width: 991px) {
      bottom: 16px;
      left: 370px;
    }

    @media (max-width: 767px) {
      bottom: 32px;
      left: 50%;
      transform: translate(-50%, 0);
      width: 100%;
    }

    .slick-active {
      button {
        &:before {
          background-color: $primary-color;
        }
      }
    }

    li {
      width: 40px;
      height: 4px;
      padding: 0;
      margin: 0 spacing(4) 0 0;

      button {
        width: 40px;
        height: 4px;
        padding: 0;
        margin: 0;
        border-radius: 5px;
        background-color: $middle-gray-color;

        &:before {
          content: '';
          background-color: $middle-gray-color;
          border-radius: 5px;
          width: 40px;
          height: 4px;
        }
      }
    }
  }

  .slick-prev {
    right: 680px;
    left: unset;
    z-index: 1;

    @media (max-width: 1199px) {
      right: 594px;
      left: unset;
    }

    @media (max-width: 991px) {
      right: 514px;
    }

    @media (max-width: 767px) {
      right: 64px;
    }
  }

  .slick-next {
    right: 632px;

    @media (max-width: 1199px) {
      right: 546px;
    }

    @media (max-width: 991px) {
      right: 466px;
    }

    @media (max-width: 767px) {
      right: 16px;
    }
  }

  .slick-arrow {
    display: flex !important;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    background-color: $bg-tertiary;
    border-radius: 5px;
    top: 322px;

    @media (max-width: 1199px) {
      top: 338px;
    }

    @media (max-width: 991px) {
      top: 208px;
    }

    @media (max-width: 767px) {
      top: 144px;
    }

    &:hover {
      background-color: $bg-tertiary;
    }

    &:before {
      content: '';
    }
  }
}
