@import "src/styles/index";

.text {
  width: 100%;
  background-color: $light-gray-color;
  border: 1px solid $middle-gray-color;
  box-sizing: border-box;
  border-radius: 5px;
  resize: none;
  margin-bottom: spacing(4);
  padding: spacing(4);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.copy {
  margin-bottom: spacing(10);
}

.facebook,
.twitter,
.copy {
  padding: spacing(4) 0;
}

.facebook {
  margin-bottom: spacing(4);
}
