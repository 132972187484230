@import "functions";

@mixin mx($n) {
  margin-left: spacing($n);
  margin-right: spacing($n);
}

@mixin my($n) {
  margin-top: spacing($n);
  margin-bottom: spacing($n);
}

@mixin px($n) {
  padding-left: spacing($n);
  padding-right: spacing($n);
}

@mixin py($n) {
  padding-top: spacing($n);
  padding-bottom: spacing($n);
}

@mixin responsive-ratio($x, $y) {
  $padding: unquote(( $y / $x ) * 100 + '%');

  padding-top: $padding;
}
