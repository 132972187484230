.notFoundPageInner {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 100px 0;
}

.notFoundPageTitle {
  font-weight: 600;
  font-size: 100px;
  margin-bottom: 70px;
}

.notFoundPageText {
  font-size: 50px;
  margin-bottom: 50px;
}
